import { NextPage } from 'next';

import { Page, PageLoading } from 'modules/common/layout';
import { NotAuthorizedOnly } from 'modules/common/auth';
import { Meta } from 'modules/common/meta';
import { Provider, PhonePage } from 'modules/login';

/**
 * Страница логина.
 */
const Index: NextPage = () => (
  <Page>
    <Meta
      title="Личный кабинет – Аполлон Займ"
      keywords="Личный кабинет Аполлон Займ, регистрация в Apollon Zaim"
      description="Личный кабинет Аполлон Займ. ✔ Регистрируйтесь и оформляйте 
      заявку онлайн по номеру телефона. ✔ Работаем круглосуточно. 
      ✔ Получите деньги на карту без комиссии за 5 минут, не выходя из дома. 
      ✔ Отправляйте заявку через сайт!"
    />
    <NotAuthorizedOnly fallback={<PageLoading />}>
      <Provider>
        <PhonePage />
      </Provider>
    </NotAuthorizedOnly>
  </Page>
);

export default Index;
